import React, { useState } from 'react';
import {
  Card,
  CardContent,
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  Button,
  MenuItem,
  FormHelperText,
  Tabs,
  Tab,
  Stack,
  Autocomplete,
  useMediaQuery,
} from '@mui/material';
import Cookies from 'js-cookie';
import { RemoveCircle } from '@mui/icons-material';
import { decryptToken } from '../../components/security/securityFunctions';
import palette from '../../theme/palette';
import AddBill from './billing/AddBill';
import AddPurchaseBill from './purchase_bill/AddPurchaseBill';

const TransactionsInfo = ({
  formik,
  setFieldValue,
  setFieldError,
  values,
  rows,
  index,
  throughOptions,
  fetchLedgers,
  ledgerOptions,
  categoryOptions,
  isCreate,
  transactionId,
}) => {
  const userToken = JSON.parse(decryptToken(Cookies.get('user_data_rejoice_daybook')) || '{}').userToken;
  const companyData = JSON.parse(decryptToken(Cookies.get('url_detailing_rejoice_daybook')) || '{}');
  const companyId = companyData.company?.company_id;
  const [options, setOptions] = useState(ledgerOptions);
  const [isNewValue, setIsNewValue] = useState(false);

  const isMobileScreen = useMediaQuery('(max-width:475px)');

  const handleRemoveClick = () => {
    const updatedRows = [...values.rows];
    updatedRows.splice(index, 1);
    setFieldValue('rows', updatedRows);
  };

  const handleAutocompleteChange = (event, value, matchedOption) => {
    if (value) {
      setFieldValue(`rows[${index}].ledgername`, value.ledgername);
      setIsNewValue(false); // Reset isNewValue if a value from options is selected
    } else {
      setFieldValue(`rows[${index}].ledgername`, event.target.value);
      setIsNewValue(event.target.value.trim() !== ''); // Set isNewValue to true if a new value is typed
    }
    // console.log(matchedOption);
    if (matchedOption) {
      setFieldValue(`rows[${index}].ledgername`, matchedOption.ledgername);
      setIsNewValue(false);
    }
  };

  return (
    <>
      <Card
        key={index}
        variant="outlined"
        raised={false}
        sx={{ backgroundColor: '#f8f8f8', borderRadius: '5px', border: 'none', boxShadow: '0px', marginBottom: '15px' }}
      >
        <CardContent sx={{ p: isMobileScreen ? '20px 5px' : 2 }}>
          <Tabs
            value={values?.rows[index]?.transactiontype || ''}
            onChange={(event, value) => setFieldValue(`rows[${index}].transactiontype`, value)}
            sx={{
              pb: 2,
              '.Mui-selected': {
                color: '#fff !important',
                backgroundColor: (theme) => {
                  switch (values?.rows[index]?.transactiontype) {
                    case 'receipt':
                      return palette.success.main;
                    case 'payment':
                      return palette.error.main;
                    case 'contra':
                      return palette.info.main;
                    case 'sales':
                      return palette.primary.main;
                    case 'purchase':
                      return palette.purple.main;
                    default:
                      return '';
                  }
                },
              },
              overflow: 'auto',
            }}
            TabIndicatorProps={{
              style: {
                backgroundColor:
                  values.rows[index].transactiontype === 'receipt'
                    ? palette.success.main
                    : values.rows[index].transactiontype === 'payment'
                      ? palette.error.main
                      : values.rows[index].transactiontype === 'contra'
                        ? palette.info.main
                        : values.rows[index].transactiontype === 'sales'
                          ? palette.primary.main
                          : values.rows[index].transactiontype === 'purchase'
                            ? palette.purple.main
                            : '',
              },
            }}
          >
            <Tab value="receipt" label="Receipt" />
            <Tab value="payment" label="Payment" />
            <Tab value="contra" label="Contra" />
            {values?.rows.length > 1 ? null : <Tab value="sales" label="Sales" />}
            {values?.rows.length > 1 ? null : <Tab value="purchase" label="Purchase" />}
          </Tabs>
          {values?.rows[index]?.transactiontype !== 'sales' && values?.rows[index]?.transactiontype !== 'purchase' ? (
            <>
              <Grid container spacing={1} rowSpacing={2}>
                <Grid item xs={12} sm={4}>
                  {values?.rows[index].transactiontype === 'contra' ? (
                    <FormControl fullWidth>
                      <InputLabel id="role-main_ledgerhead">
                        {values?.rows[index]?.transactiontype === 'receipt' ? 'From' : 'To'}
                      </InputLabel>
                      <Select
                        required
                        labelId="role-through"
                        id={`ledgername-${index}`}
                        variant="outlined"
                        label="Title"
                        name={`rows[${index}].ledgername`}
                        error={
                          !!(formik.touched?.rows?.[index]?.ledgername && formik.errors?.rows?.[index]?.ledgername)
                        }
                        onChange={(event) => {
                          const selectedValue = event.target.value;
                          setFieldValue(`rows[${index}].ledgername`, selectedValue);

                          if (selectedValue === values.rows[index].through) {
                            setFieldError(`rows[${index}].ledgername`, 'To and Through cannot be the same.');
                            setFieldError(`rows[${index}].through`, 'Through and To cannot be the same.');
                          } else {
                            setFieldError(`rows[${index}].ledgername`, undefined);
                            setFieldError(`rows[${index}].through`, undefined);
                          }
                        }}
                        onBlur={formik.handleBlur}
                        inputlabelprops={{
                          shrink: true,
                        }}
                        sx={{ marginBottom: '15px', backgroundColor: palette.background.paper }}
                        value={values?.rows[index].ledgername || ''}
                      >
                        {throughOptions.map((data) => (
                          <MenuItem key={data.id} value={data.label}>
                            {data.label}
                          </MenuItem>
                        ))}
                      </Select>
                      {formik.touched?.rows?.[index]?.ledgername && formik.errors?.rows?.[index]?.ledgername && (
                        <FormHelperText error>{formik.errors?.rows?.[index]?.ledgername}</FormHelperText>
                      )}
                    </FormControl>
                  ) : (
                    <FormControl fullWidth>
                      <Autocomplete
                        freeSolo
                        fullWidth
                        required
                        noOptionsText="Type Something..."
                        value={values?.rows[index].ledgername || ''}
                        name={`rows[${index}].ledgername`}
                        onChange={(event, value) => handleAutocompleteChange(event, value)}
                        inputValue={values?.rows[index].ledgername || ''}
                        options={ledgerOptions}
                        getOptionLabel={(option) =>
                          option?.ledgername ? option.ledgername : values.rows[index].ledgername
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label={values?.rows[index]?.transactiontype === 'receipt' ? 'From' : 'To'}
                            type="text"
                            onBlur={formik.handleBlur}
                            sx={{ marginBottom: '', backgroundColor: palette.background.paper }}
                            InputProps={{
                              ...params.InputProps,
                              onChange: (event) => {
                                const newValue = event.target.value;
                                const matchedOption = ledgerOptions.find((option) => option.ledgername === newValue);
                                handleAutocompleteChange(event, null, matchedOption);
                              },
                            }}
                          />
                        )}
                      />
                      {formik.touched?.rows?.[index]?.ledgername && formik.errors?.rows?.[index]?.ledgername && (
                        <FormHelperText error>{formik.errors?.rows?.[index]?.ledgername}</FormHelperText>
                      )}
                    </FormControl>
                  )}
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControl fullWidth>
                    <TextField
                      fullWidth
                      variant="outlined"
                      label="Amount"
                      required
                      name={`rows[${index}].amount`}
                      type="number"
                      // error={!!(formik.touched?.rows?.[index]?.amount && formik.errors?.rows?.[index]?.amount)}
                      // helperText={formik.touched?.rows?.[index]?.amount && formik.errors?.rows?.[index]?.amount}
                      onChange={(event, value) => setFieldValue(`rows[${index}].amount`, event.target.value)}
                      onBlur={(event, value) =>
                        setFieldValue(`rows[${index}].amount`, parseFloat(event.target.value).toFixed(2))
                      }
                      inputProps={{
                        sx: { textAlign: 'right' },
                      }}
                      sx={{ backgroundColor: palette.background.paper }}
                      value={values?.rows[index].amount || '0.00'}
                    />
                    {formik.touched?.rows?.[index]?.amount && formik.errors?.rows?.[index]?.amount && (
                      <FormHelperText error>{formik.errors?.rows?.[index]?.amount}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControl fullWidth>
                    <InputLabel id="role-main_ledgerhead">Through</InputLabel>
                    <Select
                      required
                      labelId="role-through"
                      id={`through-${index}`}
                      variant="outlined"
                      label="Through"
                      name={`rows[${index}].through`}
                      error={!!(formik.touched?.rows?.[index]?.through && formik.errors?.rows?.[index]?.through)}
                      onChange={(event) => {
                        const selectedValue = event.target.value;
                        setFieldValue(`rows[${index}].through`, selectedValue);

                        if (selectedValue === values.rows[index].ledgername) {
                          // console.log(selectedValue);
                          // console.log(values.rows[index].ledgername);
                          setFieldError(`rows[${index}].through`, 'Through and To cannot be the same.');
                          setFieldError(`rows[${index}].ledgername`, 'To and Through cannot be the same.');
                        } else {
                          setFieldError(`rows[${index}].ledgername`, undefined);
                          setFieldError(`rows[${index}].through`, undefined);
                        }
                      }}
                      onBlur={formik.handleBlur}
                      inputlabelprops={{
                        shrink: true,
                      }}
                      sx={{ backgroundColor: palette.background.paper }}
                      value={values?.rows[index].through || ''}
                    >
                      {throughOptions.length > 0 ? (
                        throughOptions.map((data) => (
                          <MenuItem key={data.id} value={data.label}>
                            {data.label}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem key={'through_disabled'} value={''} disabled>
                          Select
                        </MenuItem>
                      )}
                    </Select>
                    {formik.touched?.rows?.[index]?.through && formik.errors?.rows?.[index]?.through && (
                      <FormHelperText error>{formik.errors?.rows?.[index]?.through}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                {isNewValue && values.rows[index].transactiontype !== 'contra' ? (
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <InputLabel id="role-main_ledgerhead">Category</InputLabel>
                      <Select
                        required
                        fullWidth
                        labelId="role-category"
                        id="demo-simple-select1"
                        variant="outlined"
                        label="Category"
                        name={`rows[${index}].category_id`}
                        error={
                          !!(formik.touched?.rows?.[index]?.category_id && formik.errors?.rows?.[index]?.category_id)
                        }
                        onChange={(event, value) => setFieldValue(`rows[${index}].category_id`, event.target.value)}
                        onBlur={formik.handleBlur}
                        inputlabelprops={{
                          shrink: true,
                        }}
                        sx={{ backgroundColor: palette.background.paper }}
                        value={values?.rows[index].category_id || ''}
                      >
                        {categoryOptions.length > 0 ? (
                          categoryOptions.filter((data) => data.label !== 'Sales' && data.label !== 'Purchase')
                            .map((data) => {
                              return (
                                <MenuItem key={data.id} value={data.id}>
                                  {data.label}
                                </MenuItem>
                              );
                            })
                        ) : (
                          <MenuItem key={'category_disabled'} value={''}>
                            Select
                          </MenuItem>
                        )}
                      </Select>
                      {formik.touched?.rows?.[index]?.category_id && formik.errors?.rows?.[index]?.category_id && (
                        <FormHelperText error>{formik.errors?.rows?.[index]?.category_id}</FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                ) : null}
                <Grid item xs={12} sm={12}>
                  <FormControl fullWidth>
                    <TextField
                      variant="outlined"
                      label="Remarks"
                      name={`rows[${index}].remarks`}
                      type="text"
                      error={!!(formik.touched.rows?.[index]?.remarks && formik.errors.rows?.[index]?.remarks)}
                      helperText={formik.touched.rows?.[index]?.remarks && formik.errors.rows?.[index]?.remarks}
                      onChange={(event, value) => setFieldValue(`rows[${index}].remarks`, event.target.value)}
                      onBlur={formik.handleBlur}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{ backgroundColor: palette.background.paper, marginBottom: '15px' }}
                      value={values?.rows[index].remarks}
                    />
                    {formik.touched?.rows?.[index]?.remarks && formik.errors?.rows?.[index]?.remarks && (
                      <FormHelperText error>{formik.errors?.rows?.[index]?.remarks}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
              <Stack direction={'row'} display={'flex'}>
                {values.rows.length > 1 && (
                  <Stack direction={'row'} justifyContent={'right'}>
                    <Button
                      variant="text"
                      color="error"
                      onClick={handleRemoveClick}
                      style={{ marginLeft: 'auto', padding: '5px' }}
                    >
                      Remove&nbsp;
                      <RemoveCircle />
                    </Button>
                  </Stack>
                )}
              </Stack>
            </>
          ) : null}
        </CardContent>
      </Card>
    </>
  );
};

export default TransactionsInfo;
