import { useEffect, useState } from 'react';
import { useMediaQuery, Typography, Grid } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { PieChart } from '@mui/x-charts';
import Chart from 'react-google-charts';
import { API_SIGN, FETCH_DASHBOARD_CHARTS } from '../../variables/api-variables';
import { apiCall } from '../../components/functions/apiCall';
import { SkeletonLoaderSingleForm } from '../../components/skeleton_loader/skeletonLoader';
import palette from '../../theme/palette';

const DashboardChart = ({ userToken, selectedMinDate, selectedMaxDate, selectedCompanyId }) => {
  const [showLoaderDashboard, setShowLoaderDashboard] = useState(false);

  const [receiptData, setReceiptData] = useState([]);
  const [paymentData, setPaymentData] = useState([]);
  // console.log(paymentData, receiptData);

  const isMobileScreen = useMediaQuery('(max-width:475px)');

  const fetchDashboardCharts = () => {
    setShowLoaderDashboard(true);
    try {
      // API request for fetch company
      const inputData = JSON.stringify({
        api_signature: API_SIGN,
        function_type: 'dashboard_chart',
        jwt_token: userToken.userToken,
        company_id: selectedCompanyId,
        min_date_selected: selectedMinDate,
        max_date_selected: selectedMaxDate,
      });
      // console.log(inputData)
      apiCall(inputData, 'POST', FETCH_DASHBOARD_CHARTS, 'plain/text')
        .then((response) => {
          console.log(response);
          setShowLoaderDashboard(false);
          if (response.data.status === 1) {
            let resp = response.data.response;
            setReceiptData(resp.receipts_info);
            setPaymentData(resp.payments_info);
          } else {
            enqueueSnackbar(response.data.response, { variant: 'error', autoHideDuration: 15000 });
            console.log(response);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchDashboardCharts();
  }, [selectedCompanyId, selectedMinDate, selectedMaxDate]);

  const colorSchemePayments = ['#FA8072', '#CD5C5C', '#FF6347', '#FFA500', '#B38B00', '#fffa00', '#ff9400', '#b36800'];
  const colorSchemeReceipts = ['#02b2af', '#017d7d', '#98FF98', '#2E8B57', '#32CD32', '#87CEEB', '#1E90FF'];

  const formatDataForChart = (data) => {
    return [['Label', 'Value'], ...data.map((item) => [item.label, item.value])];
  };

  return showLoaderDashboard ? (
    <SkeletonLoaderSingleForm />
  ) : (
    <Grid container spacing={1} margin={'20px 0px'}>
      {receiptData.length > 0 ? (
        <Grid item xs={12} sm={6} borderRight={'solid thin #f7f7f7'}>
          <Typography variant="body1" align="center" fontWeight={'600'} fontSize={'18px'} color={palette.primary.main}>
            Receipts & Sales
          </Typography>
          <div style={{ width: '100%', height: 300 }}>
            {/* <PieChart
              colors={colorSchemeReceipts}
              slotProps={{
                legend: { direction: 'row', position: { vertical: 'bottom', horizontal: 'middle' } },
              }}
              series={[
                {
                  data: receiptData,
                  innerRadius: isMobileScreen ? 75 : 60,
                  outerRadius: 100,
                  // paddingAngle: 5,
                  cornerRadius: 5,
                  startAngle: -90,
                  endAngle: 360,
                },
              ]}
            /> */}
            <Chart
              chartType="PieChart"
              width="100%"
              height="350px"
              data={formatDataForChart(receiptData)}
              options={{
                pieHole: 0.4, // This makes it a donut chart
                colors: colorSchemeReceipts,
                legend: { position: 'bottom' },
                pieSliceText: 'value',
              }}
            />
          </div>
        </Grid>
      ) : null}
      {paymentData.length > 0 ? (
        <Grid item xs={12} sm={6}>
          <Typography variant="body1" align="center" fontWeight={'600'} fontSize={'18px'} color={'#FFA500'}>
            Payments & Purchase
          </Typography>
          <div style={{ width: '100%', height: 300 }}>
            {/* <PieChart
              colors={colorSchemePayments}
              series={[
                {
                  data: paymentData,
                  innerRadius: isMobileScreen ? 75 : 60,
                  outerRadius: 100,
                  // paddingAngle: 5,
                  cornerRadius: 5,
                  startAngle: -90,
                  endAngle: 360,
                },
              ]}
              slotProps={{
                legend: { direction: 'row', position: { vertical: 'bottom', horizontal: 'middle' } },
              }}
            /> */}
            <Chart
              chartType="PieChart"
              width="100%"
              height="350px"
              data={formatDataForChart(paymentData)}
              options={{
                pieHole: 0.4, // This makes it a donut chart
                colors: colorSchemePayments,
                legend: { position: 'bottom' },
                pieSliceText: 'value',
              }}
            />
          </div>
        </Grid>
      ) : null}


    </Grid>
  );
};

export default DashboardChart;