import { useFormik } from 'formik';
import { Alert, Button, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { enqueueSnackbar } from 'notistack';
import { API_SIGN, FORGOT_PASSWORD } from '../../../variables/api-variables';
import { apiCall } from '../../../components/functions/apiCall';

const ConfirmOtp = ({ handleNext, userName, userToken }) => {
  const [message, setMessage] = useState('');

  const formik = useFormik({
    initialValues: {
      otp: '',
    },
    onSubmit: async (values, helpers) => {
      try {
        const inputData = JSON.stringify({
          function_type: 'verify_forgot_password',
          username: userName,
          otp: values.otp,
          user_token: userToken,
          api_signature: API_SIGN,
        });
        // console.log(inputData);
        apiCall(inputData, 'POST', FORGOT_PASSWORD, 'plain/text')
          .then((response) => {
            // console.log(response);
            if (response.data.status === 1) {
              enqueueSnackbar(response.data.response.success_msg, { variant: 'success', autoHideDuration: 2000 });
              handleNext(response.data.response.username, response.data.response.user_token, values.otp);
            } else {
              enqueueSnackbar(response.data.response, { variant: 'error', autoHideDuration: 2000 });
              // setMessage(response.data.response)
            }
          })
          .catch((err) => {
            enqueueSnackbar('Check your OTP again!!', { variant: 'error', autoHideDuration: 2000 });
            console.log(err);
          });
      } catch (error) {
        enqueueSnackbar('Some error has occured.', { variant: 'error', autoHideDuration: 2000 });
        console.log(error);
      }
    },
  });

  return (
    <>
      <Typography sx={{ mb: 2 }}>Enter OTP</Typography>

      <form noValidate onSubmit={formik.handleSubmit}>
        <TextField
          error={!!(formik.touched.otp && formik.errors.otp)}
          fullWidth
          color="info"
          variant="outlined"
          size="medium"
          sx={{ marginRight: '10px' }}
          helperText={formik.touched.otp && formik.errors.otp}
          label="OTP"
          name="otp"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.otp}
        />
        {message ? (
          <Alert sx={{ margin: '20px 0px' }} severity="error">
            {' '}
            {message}
          </Alert>
        ) : (
          ''
        )}

        <Button fullWidth size="large" sx={{ marginTop: '20px' }} variant="contained" type="submit">
          Confirm OTP
        </Button>
      </form>
    </>
  );
};

export default ConfirmOtp;
